export const isProduction = true;
export const googlemapsapikey = 'AIzaSyDT8u2W9mcuJ-uESWb9ZAcd6sd6hkmAMVs';
export const environment = Object.freeze({
  production: true,
  homolog: true,
  brand: 'fr',
  reclameAquiUrl: 'https://raresponde.reclameaqui.com.br/faleconosco/index/?client=15116',
  bizutUrl: 'https://www.bizut.me/vagas/publicas/5393',
  domain: 'homolog.formula.bodytech.company',

  // tslint:disable:max-line-length
  seo: {
    DEFAULT_FULL_TITLE: 'Fórmula Academia',
    DEFAULT_TITLE: 'Fórmula',
    DEFAULT_META_DESCRIPTION: 'Venha treinar na Fórmula. Aqui você conta com os melhores profissionais e programas exclusivos de musculação e aulas coletivas para cuidar de sua saúde física e mental.',
  },

  sentry: {
    dsn: 'https://f86c124abe404d258f8f1bfd1954c4ac@sentry.dev.bodytech.company/4',
    enabled: true,
    environment: 'homolog',
    release: 'frportal-__SENTRY_RELEASE__',
    attachStacktrace: true,
  },

  services: {
    baseUrl: 'https://services.homolog.formula.bodytech.company',
    evoBaseUrl: 'https://evo-integracao.w12app.com.br',
  },

  recaptchaKey: '6Le3inEUAAAAAM1dWWSm-mh9aYQEiSXbQJl1ulxT',

  wordpress: {
    baseUrl: 'https://blog.bodytech.com.br',
  },

  apollo: {
    baseUrl: 'https://homolog.formula.bodytech.company/api',
    microservicesBaseUrl: 'https://services.authentication.dsv.bodytech.company/graphql'
  },

  docmod: {
    microservicesBaseUrl: 'https://services.docmod.dsv.bodytech.company/graphql',
    key: '25162ca3422b4a11a12dcf94da8b8a31',
    system: 'PORTAL',
    xApiKey: 'da2-vdmcecfenvcovk2yunfrma7hcm',
  },

  googleAnalytics: {
    trackingID: 'UA-46889722-2',
    tagManagerId: 'GTM-KM57M62',
    tagDmpId: 'GTM-MTCKCQSN',
  },

  facebook: {
    pixelId: '1111111111111111111',
  },

  s3buckets: {
    images: 'https://images.homolog.formula.bodytech.company',
    medias: 'https://medias.homolog.formula.bodytech.company',
  },

  evo: {
    scheduleBaseUrl: 'https://admin.formulaacademia.com.br/formula',
  },
});
